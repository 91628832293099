<template>
  <v-container>
    <v-row v-for="(photo, index) in localPhotos" :key="index" class="my-3" dense>
      <v-col>
        <v-card>
          <vueSignature ref="photos" :h="'200px'" :sigOption="photoOption" :w="'100%'"/>
        </v-card>
      </v-col>
      <v-col cols="auto">
        <kurcc-image-input
          :image.sync="photo" :name="`Photo ${index}`" :only-icon="true" rules=""
          @change="imagePicked($event, index)"/>
        <v-btn class="ms-n1 my-2" icon @click="removePhoto(index)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-btn color="info" text @click="addPhoto">
      + Add Photo
    </v-btn>
  </v-container>
</template>

<script>
import vueSignature from 'vue-signature'
import Compressor from 'compressorjs'
import { urlToFile } from '@/modules/inspection/helpers/helpers'

export default {
  name: 'KurccFormMultiChoiceAdditionalButtonsPhotoList',
  props: {
    photos: {
      type: Array,
      required: true
    }
  },
  components: {
    KurccImageInput: () => import('@/modules/inspection/components/KurccImageInput'),
    vueSignature
  },
  data () {
    return {
      photoOption: {
        penColor: 'rgb(0, 0, 0)',
        backgroundColor: 'rgb(255,255,255)'
      },
      isFirstLoad: true,
      urlToFile
    }
  },
  computed: {
    localPhotos: {
      get () {
        return this.photos
      },
      set (v) {
        this.$emit('update:photos', v)
      }
    }
  },
  watch: {
    localPhotos: {
      handler: function (v) {
        console.log(v)
        setTimeout(() => this.$lodash.forEach(v, this.setVueSignatureImage), 0.5)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    imagePicked (image, index) {
      if (image) {
        this.setVueSignatureImageFromFile(image, index)
      } else {
        this.removePhoto(index)
      }
    },
    setVueSignatureImageFromFile (image, index) {
      this.$refs.photos[index].fromDataURL(URL.createObjectURL(image))
    },
    removePhoto (index) {
      this.$lodash.forEach(this.localPhotos, (photo, index) => {
        if (photo === null) {
          this.saveImageToArray(index)
        }
      })

      const p = [...this.localPhotos]
      p.splice(index, 1)
      this.localPhotos = p
    },
    saveImageToArray (index) {
      this.localPhotos[index] = this.$refs.photos[index].save()
    },
    addPhoto () {
      const p = [...this.localPhotos]
      p.push(null)
      this.localPhotos = p
    },
    async save () {
      if (this.$lodash.isNil(this.$refs.photos)) return

      const photos = []
      await Promise.all(this.$refs.photos.map(async (ref) => {
        photos.push(await this.compressAndUpdateImageField(ref.save()))
      }))
      this.localPhotos = photos
    },
    async compressAndUpdateImageField (file) {
      return await new Promise(resolve => this.urlToFile(file, new Date().getTime(), 'image/jpeg')
        .then(file => {
          // eslint-disable-next-line no-new
          new Compressor(file, {
            quality: 0.6,
            maxWidth: 2000,
            minWidth: 2000,
            mimeType: 'image/jpeg',
            success: resolve,
            error (err) {
              console.log('err', err.message)
            }
          })
        })
      )
    },
    setVueSignatureImage (image, index) {
      this.$refs.photos[index].fromDataURL(this.$lodash.cloneDeep(image))
    }
  }
}
</script>
